import $ from 'jquery';
import 'jquery-bar-rating';
import 'jquery-bar-rating/dist/themes/fontawesome-stars.css';
import fontAwesome from 'font-awesome/scss/font-awesome.scss';
import swal from 'sweetalert';
import Tooltip from 'tooltip.js';
import Popper from 'popper.js';
import 'jquery-validation';

class App {
  constructor() {

    $(() => {
      this.init();
    });
  }

  init() {
    this.ratingInit();
    this.tooltipInit();
    this.validationInit();
    this.reviewListInit();
    this.saveReview();
    this.LoadMenu02ByMenu01();
    $(".rf-search").find(".rf-search__title").on("click touch", function () {
      $(this).closest(".rf-search").toggleClass("is-close")
      $(this).next().slideToggle()
    })

  }

  ratingInit() {
    // raing
    $('.js-rating').barrating({
      theme: 'fontawesome-stars'
    });
  }

  reviewListInit() {
    var $items = $('.rf-comment');
    const maxlen = 276;
    $items.each(function (key, data) {
      var $item = $(data);
      var _text = $item.find('.rf-comment__comment').text().trim();
      if (_text.length > maxlen) {
        var text01 = _text.substr(0, maxlen);
        var text02 = _text.substr(maxlen, _text.length);
        var $comment = $item.find('.rf-comment__comment');
        $comment.text("")
        $comment.append($("<span />", {html: text01 + '<span class="more">...</span>', class: "s-first"}))
        $comment.append($("<span />", {text: text02, class: "s-second"}))
        $comment.addClass('is-hide');
      } else {
        $item.find('.rf-comment__toggle').hide();
      }
    });
    $(".ios-toggle").on("change", function () {
      var $comment = $(this).closest(".rf-comment")
      $comment.find(".rf-comment__comment").toggleClass("is-hide")
    })
  }

  tooltipInit() {
    let rf = $('.js-tooltip');
    if (rf.length) {
      const instance = new Tooltip(rf.eq(0), {
        trigger: 'hover'
      });
    }
  }

  /**
   * バリデーション
   */
  validationInit() {
    let $form = $('#review_form');
    $("#terms").on("change", function (e) {
      if ($(this).prop("checked")) {
        $(".c-form-submit__submit button").prop("disabled", false)
      } else {
        $(".c-form-submit__submit button").prop("disabled", true)
      }
    })
    $.extend(jQuery.validator.messages, {
      required: '必須です',
      email: '有効なメールアドレスをご入力ください',
      url: '有効なURLを入力してください',
      date: '有効な日付を入力してください',
      dateISO: '有効な日付を入力してください',
      number: '数字でご入力ください。',
      digits: '有効な数字を入力してください',
      equalTo: '値が正しいか確認してください',
      maxlength: $.validator.format('{0}文字以内で入力してください'),
      minlength: $.validator.format('{0}文字以上で入力してください'),
      rangelength: $.validator.format('{0} から {1} 文字までの値を入力してください。'),
      range: $.validator.format('{0}から{1}の間の値を入力してください。'),
      max: $.validator.format('{0} 以下の値を入力してください。'),
      min: $.validator.format('{0} 以上の値を入力してください。'),
      phone: '正しい電話番号の形式で入力してください',
      zip: '正しい郵便番号の形式で入力してください'
    });

    let validate_settings = {
      onkeyup: function (element) {
        $(element).valid();
      },
      onfocusout: function (element) {
      },
      onsubmit: function () {
      },
      rules: {
        'r_tags[]': {required: true}
      },
      errorClass: 'c-errortooltip',
      errorPlacement: function (error, element) {
        if (element.attr('name') == 'r_tags[]') {
          $('#checkerror').append($(error));
          return true;
        }
        if (element.attr('name') == 'terms') {
          $('.c-form-submit__terms').append($(error));
          return true;
        }
        $(element)
          .closest('.c-form-block__block')
          .find('.c-form-block__title')
          .append($(error));
      }
    };
    let $validator = $form.validate(validate_settings);
  }

  updateMenu02ByMenu01() {
    var $trigger = $(".js-leoreview-relatedmenu01-trigger");
    var $target = $(".js-leoreview-relatedmenu01-target");
    $target.find("option").hide()
    $target.prop("disabled", true)
    var termid = $trigger.find("option:selected").val()
    if (termid && $target.find("option[data-term-id*=" + termid + "]").length) {
      $target.find("option[data-term-id*=" + termid + "]").show()
      $target.prop("disabled", false)
    } else {
      $target.find("option:first").prop("selected", true)
    }
  }

  LoadMenu02ByMenu01() {
    var $trigger = $(".js-leoreview-relatedmenu01-trigger");
    var $target = $(".js-leoreview-relatedmenu01-target");
    $target.prop("disabled", true)
    $target.find("option").hide()
    this.updateMenu02ByMenu01()
    $trigger.on("change", (e) => {
      this.updateMenu02ByMenu01()
    })

  }

  /**
   * レビュー送信時の挙動
   */
  saveReview() {
    var $form = $('#review_form');
    $form.on('submit', (e) => {
      e.preventDefault();
      if ($form.valid()) {
        var _form = document.getElementById("review_form")
        var formdata = new FormData(_form);
        formdata.append("nonce", LEOREVIEW_FRONT.nonce);
        formdata.append("action", LEOREVIEW_FRONT.action);
        $.ajax({
          url: LEOREVIEW_FRONT.endpoint,
          type: 'POST',
          data: formdata,
          processData: false,
          contentType: false,
          dataType: 'json'
        })
          .done((data) => {
            if (data.success === false) {
              $(".c-errortooltip").remove()
              var errors = data.data.errors;
              for (var i = 0; i < errors.length; i++) {
                var _name = errors[i].input.replace("[", "").replace("]", "")
                $(_form)
                  .find('*[name=' + _name + ']')
                  .closest(".c-form-block__block")
                  .find(".c-form-block__title")
                  .append($("<label />", {
                    class: "c-errortooltip",
                    for: _name,
                    text: errors[i].message,
                    style: "display: block; max-width: 90%; margin-left: auto; margin-right: auto;"
                  }))
              }
              return true;
            }
            this.saveReviewModal()
          })
          .fail((data) => {
            this.errorReviewModal(data)
          })
          .always((data) => {
          });
      }
    });
  }

  /**
   * 保存時のモーダル
   */
  saveReviewModal() {
    var leoreview_reffer = $("#leoreview_reffer").val()
    var innerHtml = '<div class="swal-title">' +
      '<span style="display: block;font-size: 0.7em;color: #ED7986;">＼Thank you!／</span>' +
      '投稿が完了しました' +
      '</div>' +
      '<div class="text">口コミは承認後に表示されます</div>' +
      '<div class="swal-footer">' +
      '<div class="swal-button-container">';
    if (leoreview_reffer) {
      innerHtml +=
        '<a href="' + leoreview_reffer + '" class="swal-button swal-button--confirm">読んでたページに戻る</a>';
    } else {

    }
    innerHtml +=
      '<div class="swal-button__loader">' +
      '<div></div>' +
      '<div></div>' +
      '<div></div>' +
      '</div>' +
      '</div>' +
      '</div>';
    swal({
      content: {
        element: 'div',
        attributes: {
          innerHTML: innerHtml
        }
      },
      // text: "",
      icon: 'success',
      button: false,
    }).then((e) => {
      window.location.reload()
    });
    ;
  }

  /**
   * エラー時のモーダル
   */
  errorReviewModal(data) {
    var html = '<div class="swal-title"><span style="display: block;font-size: 0.7em;color: #ED7986;">Error</span>エラーが発生しました</div><div class="text">エラーが発生しました。大変申し訳ございませんが、画面を更新いただき再度お試しください。</div>'

    if (data.responseJSON.data.errors.message) {
      html = '<div class="swal-title"><span style="display: block;font-size: 0.7em;color: #ED7986;">Error</span>エラーが発生しました</div><div class="text">' + data.responseJSON.data.errors.message + '</div>';
    }
    swal({
      content: {
        element: 'div',
        attributes: {
          innerHTML: html
        }
      },
      // text: "",
      icon: 'error',
      button: '閉じる'
    });
  }

}


window.ReviewForm = new App();
